<template>
    <el-card class="box-card">
        <Box type="info" :active="true">
            <div slot="box-body">
                <ul class="rawUl">
                    <li v-html="$t('register.documents.file-message')"></li>
                </ul>
            </div>
        </Box>
        <div class="mini-separator"></div>
        <el-form label-position="top" status-icon>
            <el-row :gutter="10">
                <el-col
                    v-for="(act, index) in acts"
                    v-if="showCui(act) || showAdminCi(act) || showCert(act) || isIndependent(act)"
                    :xs="24"
                    :md="12"
                    :key="index"
                >
                    <act-single :act="act"></act-single>
                </el-col>
            </el-row>

            <Box type="warning" v-if="!hasCui" :active="true">
                <div slot="box-body">
                    <ul class="rawUl">
                        <li v-html="$t('register.documents.cui-upload-message')"></li>
                        <li v-html="$t('register.documents.cui-upload-message-2')"></li>
                    </ul>
                </div>
            </Box>
            <Box type="warning" v-if="isCuiSigned && !hasAdminCI && !hasCert" :active="true">
                <div slot="box-body">
                    <ul class="rawUl">
                        <li v-html="$t('register.documents.cui-signed-message')"></li>
                    </ul>
                </div>
            </Box>
            <Box type="warning" v-if="hasCui && !isCuiSigned && !hasAdminCI" :active="true">
                <div slot="box-body">
                    <ul class="rawUl">
                        <li v-html="$t('register.documents.cui-unsigned-message')"></li>
                    </ul>
                </div>
            </Box>
        </el-form>
    </el-card>
</template>

<script>
import { get, call, sync } from 'vuex-pathify';
const ActSingle = () => import('./act.single');
export default {
    components: {
        Box: () => import('vue-info-box-element'),
        ActSingle
    },
    data: () => ({}),
    computed: {
        acts: get('account/informations@acts'),
        hasCui() {
            if (this.acts.length > 0) {
                const filtered = this.acts.filter((act) => {
                    if (act.id === 'cui' && act.document && act.document.status !== -1) return true;
                });
                if (filtered.length > 0) {
                    return true;
                }
            }
            return false;
        },
        hasAdminCI() {
            if (this.acts.length > 0) {
                const filtered = this.acts.filter((act) => {
                    if (act.id === 'admin_ci' && act.document && act.document.status !== -1) return true;
                });
                if (filtered.length > 0) {
                    return true;
                }
            }
            return false;
        },
        hasCert() {
            if (this.acts.length > 0) {
                const filtered = this.acts.filter((act) => {
                    if (act.id === 'certificat_constatator' && act.document && act.document.status !== -1) return true;
                });
                if (filtered.length > 0) {
                    return true;
                }
            }
            return false;
        },
        isCuiSigned() {
            if (this.acts.length > 0) {
                const filtered = this.acts.filter((act) => {
                    if (act.id === 'cui' && act.document && act.document.signed && act.document.status !== -1) {
                        return true;
                    }
                });
                if (filtered.length > 0) {
                    return true;
                }
            }
            return false;
        },
    },
    methods: {
        getAffiliateActs: call('account/GetAffiliateActs'),
        showCui(act) {
            return act.id && act.id === 'cui';
        },
        showAdminCi(act) {
            return act.id && act.id === 'admin_ci' && this.hasCui && !this.hasCert;
        },
        showCert(act) {
            return act.id && act.id === 'certificat_constatator' && this.hasCui && this.isCuiSigned && !this.hasAdminCI;
        },
		isIndependent(act) {
			return act.independent
		}
    },
    async mounted() {
        try {
            await this.getAffiliateActs();
        } catch (err) {
            this.$reportToSentry(e, {
                extra: {
                    fn: 'getAffiliateActs'
                }
            });
        }
    }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.box-title {
    font-size: 24px;
    color: #f17146;
    margin-bottom: 10px;
    margin-top: 10px;
}

.separator {
    background: #cccccc;
    border: none;
    height: 1px;
}

.document-tip {
    font-size: 12px;
    line-height: 12px;
    margin-top: 7px;
    margin-bottom: 5px;
    color: #7d7d7d;
}

.info-box {
    padding: 15px 10px;
    text-align: center;
    margin-bottom: 15px;
    background: #f7f7f7;
    border-radius: 4px;
    color: #828282;
    border: 1px #f1f1f1 solid;
}

.tip.info {
    margin-bottom: 0px;
}
</style>
